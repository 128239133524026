import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../layouts/index';

const Service = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter || {};

  const backgroundImagePath = frontmatter.backgroundImagePath || '';
  const backgroundImagePosition = frontmatter.backgroundImagePosition || 'top right';
  const backgroundImageSize = frontmatter.backgroundImageSize || 0;
  const backgroundStyle = {
    backgroundImage: "url(" + backgroundImagePath + ")",
    backgroundPosition: backgroundImagePosition,
    backgroundRepeat: "no-repeat",
    backgroundSize: backgroundImageSize,
  }

  const { title } = frontmatter || 'No Title';
  const { subtitle } = frontmatter || 'No Subtitle';
  const { html } = data.markdownRemark || 'No Remark';

  return (
    <Layout bodyClass="page-service" > 
      <SEO title={title} />
        <div className="container pt-4 pt-md-10" style={ backgroundStyle } >
          <div className="row justify-content-start">
              <div className="service service-single" >
                <h1 className="title">{title}</h1>
                <p style={ { marginBottom: "80px" } } >{subtitle}</p>
                <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
              </div>
          </div>
        </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        path
        backgroundImagePath
        backgroundImagePosition
        backgroundImageSize
      }
      html
    }
  }
`;

export default Service;